/* eslint-disable */

function finishFlagCheckSet() {

  var not = $(this).attr('id') === 'flagCheckSetButtonNot';

	var $flagCheckSetOverview = $('#flagCheckSetOverview');
	var flagCheckSetNr = 0;
	try {
		flagCheckSetNr = $flagCheckSetOverview.children().last().find(
			'input[type=checkbox]').first().attr('name').replace(
			/data\[FlagCheck\]\[/, '').replace(/\].*/, '');
		flagCheckSetNr++;
	} catch (e) {
	}

	var $flagCheckSetDiv = $('<div>').addClass('flagCheckSet');

	$('input.myFlag:checked').each(
		function(index, elem) {
			if ($flagCheckSetDiv.children().length > 0) {
				$('<span>').addClass('logicalOperatorOr').html('ODER')
					.appendTo($flagCheckSetDiv);
			}

			var numId = getPrefixedNumericId(elem.id);
			var cbId = 'FlagCheck' + flagCheckSetNr + '_' + numId;
			var label = $(elem).getLabel().text();

			// create input for filter
			$('<input>').attr(
				{
					type : 'checkbox',
					name : 'data[FlagCheck][' + flagCheckSetNr + ']'+ (not ? '[NOT]' : '') +'[' + numId
						+ ']',
					value : label,
					id : cbId,
					checked : 'checked'
				}).appendTo($flagCheckSetDiv);
			// create label for overview
			$('<label>').attr('for', cbId).html(label).appendTo(
				$flagCheckSetDiv);
		}).attr('checked', false);

	if ($flagCheckSetDiv.children().length > 0) {
		$flagCheckSetDiv.prepend('(').append(')').append(
			createFlagCheckSetRemoveButton());
    if (not) {
      $flagCheckSetDiv.prepend('NICHT ');
    }
		if ($flagCheckSetOverview.children().length == 0) {
			// first append
			$flagCheckSetOverview.show();
		} else {
			$flagCheckSetDiv.prepend($('<span>').addClass('logicalOperatorAnd')
				.html('UND'));
		}
		$flagCheckSetOverview.append($flagCheckSetDiv);
	}

	return false; // if this is a click callback then we don't want the click
	// to resume
}

function createFlagCheckSetRemoveButton() {
	$template = $('#flagCheckSetRemoveTemplate');
	if ($template.length > 0) {
		return $template.html();
	} else {
		return $('<a>').addClass('flagCheckSetRemove').html($.my.i18n.remove)
			.button({
				icons : {
					primary : 'ui-icon-minusthick'
				}
			});
	}
}

function scanSetFlags(headerSelector) {
	$(headerSelector).each(
		function() {
			$(this).find('span.flagsSet').remove();
			var flags = new Array();
			$(this).next().find('input[type="checkbox"]:checked').each(
				function() {
					flags[flags.length] = $(this).parent().find('abbr').text();
				});
			if (flags.length > 0) {
				var report = '';
				for (var i = 0; i < flags.length; i++) {
					report += '<abbr title="' + flags[i] + '">'
						+ flags[i].substring(0, Math.floor(100 / flags.length))
						+ '.</abbr> ';
				}

				report = report.trim();
				report = ' <span class="flagsSet pull-right small">(gesetzt: '
					+ report + ')</span>';
				$(this).append(report);
			}
		});
};
