/* eslint-disable */

/**
 * taken from HTML5-boilerplate plugin.js: Avoid `console` errors in browsers
 * that lack a console.
 */
(function() {
  var method;
  var noop = function () {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

/**
 * setup my namespace
 */
if (!$.my) {
	$.my = {};
}

/**
 * returns an url for the current controller ending with /, but without action
 */
$.my.getControllerBaseUrl = function() {
	this.assertBaseUrlSet();
	var r = this.url.base;
	if (this.url.plugin) {
		r += this.url.plugin + '/';
	}
	return r + this.url.controller + '/';
}

$.my.getBaseUrl = function() {
	this.assertBaseUrlSet();
	return this.url.base;
};

$.my.assertBaseUrlSet = function() {
	if (!this.url.base) {
		this.url.base = $('base').attr('href');
		if (!this.url.base.endsWith('/')) {
			this.url.base = this.url.base + '/';
		}
	}
};

$.my.flashmessage = function(msg, type) {
	var opts = {
		timeToFade : 5000,
		type : 'shadow-box '
	};
	if (type) {
		opts.type += type;
		if (type == 'error') {
			opts.timeToFade = 60000;
			opts.closeButton = true;
		}
	}

	if ($.flashmessage) {
		$.flashmessage(msg, opts);
	} else {
		alert(type + ': ' + msg);
	}
};

$.my.successHandler = function(data, jqXHR, settings) {
	// console.log('successHandler: ', data, jqXHR, settings);

	if (!data.message) {
		data.message = 'ATTN: no message supplied!';
	}

	var type = null;
	if (data.success == true) {
		type = 'success';
	} else {
		type = 'error';
	}
	$.my.flashmessage(data.message, type);

};

$.my.errorHandler = function(jqXHR, textStatus, errorThrown) {
	// errorThrown ie. "Internal Server Error"
	// console.log('handleError: ', jqXHR, textStatus, errorThrown);
	var data = null, msg = null;
	try {
		data = $.parseJSON(jqXHR.responseText);
	} catch (e) {
		msg = jqXHR.responseText;
	}

	if (data && data.message) {
		msg = data.message;
	}
	$.my.flashmessage(msg, 'error');
};




$.my.init = {
	initialised : {
    backToDashboardEvent: false,
		noLabelFieldsets : false,
		editableInline : false,
		dateTimePickers : false,
		filterPanels : false
	}
};

$.my.init.initContentTableForm = function() {
	if (this.initialised.contentTableForm) {
		return;
	}
	this.initialised.contentTableForm = true;

	var $selectionCol = $('#content-table .selection');

	$('#TableForm').on('submit', function(){
		if ($('input[type=checkbox]:checked').length==0){
			alert("Nothing selected!");
			return false;
		}
	});

	var toggleDisabled = function(){
		if ($selectionCol.find('input[type=checkbox]:checked').length==0){
			$selectionCol.find('.dropdown-toggle').attr('disabled', 'disabled');
		}else{
			$selectionCol.find('.dropdown-toggle').removeAttr('disabled');
		}
	};

	$('#content-table').on('click', 'td.selection input[type=checkbox]', toggleDisabled);
	toggleDisabled();

	$(document).on('click', '#content-table th.selection .btn-toggle-selection', function(){
		$selectionCol.find('input[type=checkbox]').click();
		return false;
	});
};

$.my.init.initSortTHs = function() {
	if (this.initialised.sortTHs) {
		return;
	}
	this.initialised.sortTHs = true;
	$('table th a.asc').append('&nbsp;<i class="fa fa-sort-asc"></i>');
	$('table th a.desc').append('&nbsp;<i class="fa fa-sort-desc"></i>');
};

$.my.init.initNoLabelFieldsets = function() {
	if (this.initialised.noLabelFieldsets) {
		return;
	}
	this.initialised.noLabelFieldsets = true;
	$(
		'fieldset.no-labels input[type!="checkbox"], fieldset.no-labels select, fieldset.no-labels textarea')
		.tooltipLabels();
};

$.my.init.initEditableInline = function() {
	if (this.initialised.editableInline) {
		return;
	}
	this.initialised.editableInline = true;

  var $elements = $('.editable-inline')
  if ($elements.length==0) {
    return
  }

	$.fn.editable.defaults.mode = 'inline';
	$.fn.editable.defaults.emptytext = $.my.i18n.empty;
	$.fn.editable.defaults.toggle = 'click';
	$.fn.editableform.buttons = '<button type="submit" class="btn btn-primary btn-xs editable-submit"><i class="fa fa-check"></i></button><button type="button" class="btn btn-default btn-xs editable-cancel"><i class="fa fa-remove"></i></button>';

	$elements
	.each(function(){
		var opts = {
			container: 'body',
			error: function(response, newValue) {

				if (response.responseJSON){
					if(response.responseJSON.info && response.responseJSON.info.validationErrors){
						// most detailed info: validationErrors
						var errors = response.responseJSON.info.validationErrors;
						var msg = "";
						for (prop in errors){
							if (errors.hasOwnProperty(prop)){
								msg += errors[prop][0] + "\n";
							}
						}
						return msg;
					}else if(response.responseJSON.message){
						// we can at least use standard exception message
						return response.responseJSON.message;
					}
				}
				// absolute default: just the plain responseText
				return response.responseText;
			},
			datepicker : {
				language : 'de'
			}
		};
		var template = $(this).data('combodate-template');
		if(template){
			opts['template'] = template;
		}
		$(this).editable(opts);
	});
	$('.editable-tooltip')
	.tooltip({
		title : $.my.i18n.editableTooltip,
		delay : {
			show : 700,
			hide : 100
		},
		container : 'body',
		placement : 'left'
	})
	;

};

$.my.init.initFilterPanels = function() {
	if (this.initialised.filterPanels) {
		return;
	}
	this.initialised.filterPanels = true;

	// install filter tabs
//	var $filterTabs = $('#filterTabs');

	$('#filterTabs > li:first, .tab-content .tab-pane:first')
		.addClass('active');

	$('.filter-form').filterFormHandling();
	$('#filterSummary').filterSummary('form#filters');

	// position filter buttons next to last tab
	// var $li = $filterTabs.find('li:last');
	// var left = $li.offset().left + $li.outerWidth() + 10;
	// $('.filter-buttons').css('left', left + 'px');
};

$.my.init.dateTimePickers = function() {
	if (this.initialised.dateTimePickers) {
		return;
	}
	this.initialised.dateTimePickers = true;

	var $elements = $('.date-time-picker')
  if ($elements.length==0) {
    return
  }

  $elements
	.datetimepicker({
		icons: {
			time: 'fa fa-clock-o',
			date: 'fa fa-calendar',
			previous: 'fa fa-arrow-left',
			next: 'fa fa-arrow-right',
			up: 'fa fa-arrow-up',
			down: 'fa fa-arrow-down',
			today: 'fa fa-calendar-check-o',
			clear: 'fa fa-trash',
			close: 'fa fa-times'
		},
		format: "YYYY-MM-DD",
		locale: "de",
		allowInputToggle: true,
		sideBySide: true
	});
};

$.my.init.initVisibleColumnsForm = function() {

	// this was in evmgr.adjust-to-bootstrap.js
	// but actually it only concerns itself with the VisibleColumnsForm
	$('#column-selector').each(function() {
		var l = $(this).parent().offset().left, w = $(window).width();
		$(this).width(w - 2 * l);
	});

	$('#virtualcolumnsaccordion input.select-all').change(function() {
		var $me = $(this);
		var $panel = $me.parents('.panel');
		$panel.find('.panel-collapse').collapse('show');
		var $target = $panel.find('input[type=checkbox]');
		if ($me.is(':checked')) {
			$target.attr('checked', 'checked');
		} else {
			$target.removeAttr('checked');
		}
		return false;
	});
};

/**
 * initialise event URL part hint update
 */
$.my.init.initUrlPartHint = function() {
	if (this.initialised.urlPartHint) {
		return;
	}
	this.initialised.urlPartHint = true;

	var $hint = $('#url-part-hint');
	var $field = $('#EventPackageUrlPart');

  if (!$hint.length || !$field.length) {
    return
  }

	var stdHint = $hint.text();
	var updateHint = function(){
		var val = $field.val().toLowerCase();
		if (val!=''){
			$hint.text(val);
		}else{
			$hint.text(stdHint);
		}
	};
	$field.on('change input', updateHint);
	updateHint();
}

/**
 * initialise event URL part hint update
 */
$.my.init.initBackToDashboardEvent = function() {
	if (this.initialised.backToDashboardEvent) {
		return;
	}
	this.initialised.backToDashboardEvent = true;

	$(document).on('click', '.back-to-dashboard, #back-to-dashboard', function (e) {
    var here = window.location.hostname

		if (window.parent != window) {
			var targetOrigin = 'https://' + here
			console.log('doing postMessage to targetOrigin ' + targetOrigin)

			window.parent.postMessage({
				type: 'back-to-dashboard.click',
				href: this.href
			}, targetOrigin)

			e.preventDefault()
			return false
		} else {
			console.log('not in a frame, not handling click')
		}
	})

  console.log('backToDashboardEvent initialised.')
}

$.my.callWorker = function(){
	$.get($.my.getBaseUrl()+"worker.php")
	.done(function(data, textStatus, jqXHR){
		console.log("worker successfully called. result: "+data);
	})
	.fail(function(jqXHR, textStatus, errorThrown){
		console.log("worker failed. status: "+textStatus);
		console.log("error: ", errorThrown);
	});
};

/**
 * namespace for signup specific functionality
 */
$.my.signup =  {
};

/**
 * handles conditional show/hide of entourage name fields if they exist.
 */
$.my.signup.toggleEntourageName = $.debounce(250, function(ev){
	var $entourageCount = $('#InviteEntourageCount');
	var entourageMax = $entourageCount.attr('max');
	var count = $entourageCount.val();

	for (var i=1; i<=entourageMax; i++){
		var $entourageName = $('#entourage-name-'+i);

		if (i<=count){
			$entourageName.slideDown('slow');
			$('textarea').trigger('autosize:update');
		}else{
			$entourageName.slideUp('slow');
			$entourageName.find('input[type="text"], input[type="number"], textarea').val('');
			$entourageName.find('input[type="radio"]').attr('checked', false);
		}
	}
});


