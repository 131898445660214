function showAddressPreviewPopup(data, offset){

	var popup = $('#addressPreviewPopup');
	if (! popup.is(':visible') || popup.data('contactId')!=data.id ){
		popup.data('contactId', data.id);
		popup.html(formatAddressPreview(data))
		.css({
			left: ( offset.left+115 )+"px",
			top: offset.top
		})
		.fadeIn(200);
	}
}

function formatAddressPreview(data){
	var begin;

	if (data.address=='Firma'){
		data.address = '';
	}

	// let null be ''
	for(var prop in data) {
		if(data.hasOwnProperty(prop)){
			if (data[prop]==null){
				data[prop]='';
			}
		}
	}

	if (data.country=='--'){
		data.country='';
	}
	if (data.country!='' && data.countryLabel!=undefined && data.countryLabel!=''){
		data.country = data.countryLabel;
	}
	data.country = data.country.toUpperCase();
	if (data.country=='AT' || data.country=='ÖSTERREICH' || data.country=='AUSTRIA'){
		data.country = '';
	}

	if (data.country!='' && data.city!=''){
		data.city = data.city.toUpperCase();
	}

	if (data.company!=''){
		if (data.department!=''){
			data.company += '<br/>' + data.department +' ';
		}
		begin = data.company +'<br/>'+ data.address +' ';
	}else{
		begin = data.address +'<br/>';
	}

	var line3 =
	(data.jobTitle2!='' ? data.jobTitle2+' ' : '')
	+ (data.title!='' ? data.title+' ' : '')
	+ (data.firstName!='' ? data.firstName+' ' : '')
	+ (data.lastName!='' ? data.lastName : '')
	+ (data.titleAnglo!='' ? ', '+data.titleAnglo : '');

	var newAddress =
	begin
	+ (data.jobTitle!='' ? data.jobTitle+' <br/>' : '')
	+ (line3!='' ? line3 + '<br/>' : '')
	+ data.street + '<br/>'
	+ data.zipCode +' ' +data.city + '<br/>'
	+ data.country;

	return newAddress;
}

function stripTitle(title){
	// we need to wrap our string in an element
	var $t = $('<div></div>').html(title);
	// replace all tags except sup with its contents
	$t.find('*').not('sup').each(function() {
		var content = $(this).contents();
		$(this).replaceWith(content);
	});
	// return without wrapper element
	return $t.html();
}

function updateAddressPreview(){

	var organisation, street, postalCode, city, country, countryLabel;

	// check whether there are multiple addresses
	var primaryAddress = $('#primaryAddressRadio input[type="radio"]:checked').val();

	if (primaryAddress==undefined){
		organisation = $('#ContactOrganisation').val();
		street = $('#ContactStreet').val();
		postalCode = $('#ContactZipCode').val();
		city = $('#ContactCity').val();
		country = $('#ContactCountry').val();
		countryLabel = null;
	}else{
		var z = primaryAddress-1;
		organisation = $('#Address'+z+'Name').val();
		street = $('#Address'+z+'Street').val();
		postalCode = $('#Address'+z+'PostalCode').val();
		city = $('#Address'+z+'City').val();
		country = $('#Address'+z+'Country').val();
		countryLabel = $('#Address'+z+'Country :selected').text();
	}

	var data = {
		jobTitle: $('#ContactJobTitle').val(),
		jobTitle2: $('#ContactJobTitle2').val(),
		title: stripTitle( $('#ContactTitle').val() ),
		titleAnglo: stripTitle( $('#ContactTitleAnglo').val() ),
		address: $('#ContactFormOfAddress').val(),
		company: organisation,
		department: $('#ContactDepartment').val(),
		country: country,
		countryLabel: countryLabel,
		city: city,
		firstName: $('#ContactFirstName').val(),
		lastName: $('#ContactLastName').val(),
		street: street,
		zipCode: postalCode
	};

	$('#addressPreview').empty().append(formatAddressPreview(data));
};

