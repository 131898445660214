function parseCookies() {
	var cs = document.cookie;
	var table = new Array();

	elems = cs.split(';');
	for (var i = 0; i < elems.length; i++) {
		jQuery.trim(elems[i]);
		var pair = elems[i].split('=');
		table[pair[0]] = pair[1];
	}

	return table;
}

getBaseUrl = function() {
	return $('base').attr('href');
};

forUrl = function(url) {
	return getBaseUrl() + url.substr(1);
};

function getAjaxModal() {
	var modal = $('#ajax-modal');
	if (modal.length == 0) {
		$('body').append(
			'<div id="ajax-modal" class="modal fade" role="dialog">'
			+'<div class="modal-dialog modal-lg" role="document">'
			+'<div class="modal-content">'
			+'<div class="modal-body">'
			+'</div>'
			+'</div>'
			+'</div>'
			+'</div>'
		);
		modal = $('#ajax-modal');
	}
	return modal;
}

function getOverlayer() {
	var overlayer = $('#overlayer');
	if (overlayer.length == 0) {
		// create
		$('body').append('<div id="overlayer" class="shadow-box"></div>');
		overlayer = $('#overlayer');
	}
	return overlayer;
}

function updateDateSelects(selectedDate, field_id) {
	if (selectedDate.getDate() < 10) {
		$('#' + field_id + 'Day option[value=0' + selectedDate.getDate() + ']')
			.attr('selected', 'selected');
	} else {
		$('#' + field_id + 'Day option[value=' + selectedDate.getDate() + ']')
			.attr('selected', 'selected');
	}
	if (selectedDate.getMonth() < 9) {
		$(
			'#' + field_id + 'Month option[value=0'
				+ (selectedDate.getMonth() + 1) + ']').attr('selected',
			'selected');
	} else {
		$(
			'#' + field_id + 'Month option[value='
				+ (selectedDate.getMonth() + 1) + ']').attr('selected',
			'selected');
	}
	$(
		'#' + field_id + 'Year option[value=' + (selectedDate.getFullYear())
			+ ']').attr('selected', 'selected');
}

function updateTimeSelects(selectedDate, field_id) {
	if (selectedDate.getHours() < 10) {
		$(
			'#' + field_id + 'Hour option[value=0' + selectedDate.getHours()
				+ ']').attr('selected', 'selected');
	} else {
		$('#' + field_id + 'Hour option[value=' + selectedDate.getHours() + ']')
			.attr('selected', 'selected');
	}
	if (selectedDate.getMinutes() < 9) {
		$(
			'#' + field_id + 'Min option[value=0' + (selectedDate.getMinutes())
				+ ']').attr('selected', 'selected');
	} else {
		$(
			'#' + field_id + 'Min option[value=' + (selectedDate.getMinutes())
				+ ']').attr('selected', 'selected');
	}
}

/**
 * removes actions export and invite from supplied action string
 */
function removeSpecialAction(action) {
	var re = new RegExp(/\/(invite|export)\b/);
	return action.replace(re, '');
}

function getPrefixedNumericId(id) {
	var matches = id.match(/\d+$/);
	if (matches) {
		return matches[0];
	} else {
		return null;
	}
}

startSavingAnimation = function(dom, color) {
	dom.animate({
		backgroundColor : color
	}, 400).animate({
		backgroundColor : 'transparent'
	}, 400, 'swing', function() {
		// In the tests animations are turned off - i.e they happen
		// instantaneously.
		// Hence we need to prevent this from becomming an unbounded recursion.
		setTimeout(function() {
			startSavingAnimation(dom, color);
		}, 10);
	});
};

var stopSavingAnimation = function(dom) {
	dom.stop(true).css({
		backgroundColor : ''
	});
};

function highlightFulltextTerms() {
	var terms = $('#ContactFulltext').val();
	if (terms && terms.length > 0) {
		terms = terms.split(' ');
		$.each(terms, function(index, value) {
			terms[index] = value.toLowerCase().replace(/[^a-z0-9]/g, '');
		});
		// console.log('going to highlight terms:');
		// console.log(terms);
		$('.index td').highlight(terms);
	}
}

/**
 * returns the first integer match (/\d+/) or false.
 */
String.prototype.parseNumber = function() {
	var res = this.match(/\d+/);
	if (res.length > 0) {
		return res[0];
	} else {
		return false;
	}
};

String.prototype.endsWith = function(suffix) {
	return this.indexOf(suffix, this.length - suffix.length) !== -1;
};

/**
 * adapted from
 * http://dense13.com/blog/2009/05/03/converting-string-to-slug-javascript/
 *
 * @param str
 * @returns
 */
function stringToSlug(str) {
	str = str.replace(/^\s+|\s+$/g, ''); // trim
	str = str.toLowerCase();
	// special treatment of german characters
	str = str.replace(/ö/g, 'oe');
	str = str.replace(/ä/g, 'ae');
	str = str.replace(/ü/g, 'ue');
	str = str.replace(/ß/g, 'sz');

	// remove accents, swap ñ for n, etc
	var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
	var to = "aaaaeeeeiiiioooouuuunc------";
	for (var i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	}

	str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
	.replace(/\s+/g, '-') // collapse whitespace and replace by -
	.replace(/-+/g, '-'); // collapse dashes

	return str;
}

/**
 * helper method for ACL plugin right queries: only query when needed...
 */
function wrapped_init_register_role_controller_toggle_right(app_root_url, role_id, plugin, controller, missing_aco_text){
	var id = controller;
	if (plugin!=""){
		id = plugin +'-'+ id;
	}
	$('#'+id).on('acl.controller.shown', function(){
		init_register_role_controller_toggle_right(app_root_url, role_id, plugin, controller, missing_aco_text);
	});
}

/**
 * install event listeners in ACL plugin...
 */
function aclRolePermissionsInit(){
	$('#plugin_acl').find('.sub-title').click(function(){
		var id = $(this).attr('id');
		$('tr.'+id).show();
		$(this).trigger('acl.controller.shown');
	});

}

function openWindowCentered(url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;

	var opts = 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, modal=yes, alwaysRaised=yes';
    var newWindow = window.open(url, title, opts+', width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus();
    }
	return newWindow;
}

function scrollToFirstInputError(){
	var $elem = $('.has-error .form-control');
	if(!$elem.length){
		return;
	}

	$elem = $elem.first();
	console.log($elem);

	$("html, body").animate({scrollTop: $elem.offset().top-50}, 1000, 'swing', function() {
		// TODO does not really work well: animation often stops in chrome
		// $('.error-msg').blink(2);
	});
}
