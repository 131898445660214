$.fn.parentTypeahead = function() {
	$div = this;

	var $acTextField = $div.find('#ContactParentAutoComplete');
	var $parentEditButton = $div.find('#ParentEditButton');
	var $parentRemoveButton = $div.find('#ParentRemoveButton');
	var $parentLink = $div.find('#ParentLink');
	var $parentEmpty = $div.find('#ParentEmpty');
	var $parentIdField = $div.find('#ContactParentId');

	var updateState = function(editing) {
		if ($parentIdField.val()){
			$parentEmpty.hide();
			$parentLink.show();
			$parentRemoveButton.removeAttr('disabled');
		}else{
			$parentEmpty.show();
			$parentLink.hide();
			$parentRemoveButton.attr('disabled', 'disabled');
		}

		if (editing) {
			$acTextField.show();
			$parentLink.hide();
			$parentEmpty.hide();
			$parentEditButton.hide();
			$parentRemoveButton.hide();
		} else {
			$acTextField.hide();
			$parentLink.show();
			$parentEditButton.show();
			$parentRemoveButton.show();
		}

		// special treatment of completely disabled parent-typeahead
		if ($div.hasClass('disabled')){
			$parentRemoveButton.attr('disabled', 'disabled');
			$parentEditButton.attr('disabled', 'disabled');
			$acTextField.hide();
			return;
		}

	};

	updateState();

	if ($div.hasClass('disabled')){
		return;
	}

	var objects = [];
	var map = {};

	$acTextField.hide().typeahead(
		{
			source : function(q, process) {
				$.get(
					forUrl('/ajax/' + $.my.url.controller + '/list_parent.json')
						+ '?term=' + q).done(
					function(result, status, responseObj) {
						objects = [], map = {};
						// var data = $.parseJSON(result);
						$.each(result, function(i, object) {
							map[object.id] = object;
							// objects.push(object.label);
						});
						process(result);
					}).fail(function(a, b, c) {
					// console.log(a, b, c);
				});

			},
			// minLength: 0,
			updater : function(item) {
				$parentIdField.val(item.id);
				$parentLink.attr('href',
					// XXX this should be form_view depending on permissions
					forUrl('/' + $.my.url.controller + '/edit/' + item.id)).text(
					item.name).show();
				updateState(false);
				return item;
			}
		// focus: function(event, ui){
		// event.preventDefault();
		// }
		});
	$acTextField.keydown(function(event) {
		switch (event.keyCode) {
		case 13:
			event.preventDefault();
			return false;
			break;
		case 27:
			$(this).blur();
			return false;
			break;
		default:
			// return false;
			break;
		}
	});
	$acTextField.blur(function(event) {
		updateState(false);
	});
	$parentEditButton.click(function(e) {
		e.preventDefault();
		updateState(true);
		$acTextField.val('');
		$acTextField.focus();
		return false;
	});
	$parentRemoveButton.click(function(e) {
		e.preventDefault();
		$parentIdField.val('');
		$parentLink.attr('href', '').text('').hide();
		updateState();
		return false;
	});
};

jQuery.fn.getLabel = function() {
	return $('label[for=' + this.attr('id') + ']');
};
jQuery.extend({
	getField : function(fieldName) {
		return jQuery('input[name*="[' + fieldName + ']"]');
	}
});
jQuery.fn.getField = function(fieldName) {
	return this.find('input[name*="[' + fieldName + ']"], select[name*="['
		+ fieldName + ']"]');
};

/**
 * extend jQuery with custom filter :regex
 * s. https://j11y.io/javascript/regex-selector-for-jquery/
 */
jQuery.expr[':'].regex = function(elem, index, match) {
    var matchParams = match[3].split(','),
        validLabels = /^(data|css):/,
        attr = {
            method: matchParams[0].match(validLabels) ?
                        matchParams[0].split(':')[0] : 'attr',
            property: matchParams.shift().replace(validLabels,'')
        },
        regexFlags = 'ig',
        regex = new RegExp(matchParams.join('').replace(/^\s+|\s+$/g,''), regexFlags);
    return regex.test(jQuery(elem)[attr.method](attr.property));
}

jQuery.fn.required = function(isRequired){
	if (isRequired==undefined){
		isRequired = true;
	}

	this.attr('required', isRequired);
	this.closest('.form-group').toggleClass('required', isRequired);
};

jQuery.fn.filterSummary = function(filterForm) {
	// make filter summary
	var filter = '';
	$(filterForm).find(
		'input[type=text], input[type=checkbox]:not(.myFlag), select').each(
		function() {
			var val;

			if (this.type == 'text') {
				val = $(this).val();
			} else if ($(this).is('select')) {
				// XXX exception BdateFrom and BdateTo
				var $selected = $(this).find('option:selected');
				if ($selected.val() != '') {
					val = $selected.text();
				}
			} else if ($(this).is(':checkbox:checked') && $(this).parents('#flagCheckSetOverview').length==0) {
				val = 'ja';
			}
			if (val) {
				filter += $(this).getLabel().text() + ': ' + val + '; ';
			}

		});

	$(filterForm).find('input#filter_extra_conditions_summary').each(function(){
		filter += $(this).val();
	});

	var $flagCheckSetOverview = $('#flagCheckSetOverview');
	var flags = $flagCheckSetOverview.text().trim();
	if (filter.length > 0 || flags.length > 0) {
		var summary = '<strong>Aktive Filter:</strong> ' + filter;
		if (flags.length > 0) {
			summary += '<strong>Flags:</strong> ' + flags;
			$flagCheckSetOverview.show();
		}
		this.html(summary).show(); // this is field we've been called on
	} else {
		this.hide();
	}
};
jQuery.fn.filterFormHandling = function() {
	this.submit(function() {
		finishFlagCheckSet();
		return true;
	});
}

jQuery.fn.birthdateUnknown = function() {
	var $cb = this;
	var $dateSelects = $('#ContactBirthdateMonth, #ContactBirthdateDay, #ContactBirthdateYear');
	var enableBirthdate = function() {
		if ($cb.is(':checked')) {
			$dateSelects.attr("disabled", true);
		} else {
			$dateSelects.removeAttr("disabled");
		}
	}

	enableBirthdate();
	$cb.click(enableBirthdate);
};

/**
 * expects to be called on checkboxes only, checkboxes of one group need to be
 * in the same fieldset.
 */
$.fn.radioCheckbox = function() {
	$(document).on(
		'change',
		this.selector,
		function() {
			var $cb = $(this);
			if ($cb.is(':checkbox') && $cb.is(':checked')) {
				var group = /\b(group-\S+)/.exec($cb[0].className);

				$cb.closest('fieldset').find('input:checkbox.' + group).each(
					function() {
						if (!$(this).is($cb)) {
							$(this).attr('checked', false);
						}
					});
			}
		});
};

$.fn.tooltipLabels = function() {
	this.on('focus mouseenter', function() {
		var $this = $(this);
		var $label = $this.getLabel();
		var top;
		$label
		.hide()
		.removeClass('sr-only')
		.addClass('dropdown-menu');
		top = ($this.position().top - $label.outerHeight() - 15);
		$label.css({
			position : 'absolute',
			top : top + 'px',
			left : ($this.position().left) + 'px',
			width : ($this.outerWidth()) + 'px'
		}).show();
	}).on('blur', function() {
		$(this).getLabel().hide();
	}).on('mouseleave', function() {
		if (!$(this).is(':focus')) {
			$(this).getLabel().hide();
		}
	});
};

$.fn.addressCopy = function(opts) {
	var options = {
		buttonTextPrefix : 'Copy from ',
		blocked : []
	};
	$.extend(options, opts);
	var $addresses = this;

	$addresses.each(function(index) {
		var $address = $(this);

		var $other;
		$addresses.each(function() {
			if (!$(this).is($address)) {
				$other = $(this);
			}
		});

		if ($other && $.inArray(index, options.blocked) == -1) {
			var $copyButton = $('<a class="btn btn-xs btn-primary"></a>').css({
				marginLeft : '20px'
			}).text(
				options.buttonTextPrefix
					+ $other.prev().contents().first().text().trim()).click(
				function() {
					$address.copyAddressFrom($other);
					$address.find('input[value=""]:text').first().focus();
				}).appendTo($address.prev());
		}
	});

};

$.fn.copyAddressFrom = function(src) {
	var $src = $(src);
	var $dest = this;
	var fieldMap = {
		form_of_address : 'attn_form_of_address',
		attn_form_of_address : 'form_of_address',
		last_name : 'attn_last_name',
		attn_last_name : 'last_name',
		first_name : 'attn_first_name',
		attn_first_name : 'first_name',
		organisation : 'name',
		name : 'organisation',
		email : 'email',
		phone : 'phone'
	};

	$.each(fieldMap, function(from, to) {
		$from = $src.getField(from);
		$to = $dest.getField(to);
		if ($from.length > 0 && $to.length > 0) {
			if ($to.is('select')) {
				$to.find('option').attr('selected', false);
				$to.find('option[value="' + $from.val() + '"]').attr('selected',
					true);
			} else {
				$to.val($from.val());
			}
		}
	});
};

$.inspect = function(obj) {
	var str = '';
	$.each(obj, function(a, b) {
		if (b != null && typeof (b) == 'object') {
			b = $.inspect(b);
		}
		str += '<strong>' + a + ':</strong> ' + b + "; ";
	});
	if (str != '') {
		str = ' [ ' + str + ' ] ';
	}
	return str;
};

// jQuery plugin to prevent double submission of forms
jQuery.fn.preventDoubleSubmission = function() {
	$(this).on('submit', function(e) {
		var $form = $(this);

		if ($form.data('submitted') === true) {
			// Previously submitted - don't submit again
			e.preventDefault();
		} else {
			// Mark it so that the next submit can be ignored
			$form.data('submitted', true);
		}
	});

	// Keep chainability
	return this;
};


jQuery.fn.blink = function (count) {
    var $this = $(this);

    count = count - 1 || 0;

    $this.animate({opacity: .25}, 300, function () {
        $this.animate({opacity: 1}, 300, function () {
            if (count > 0) {
                $this.blink(count);
            }
        });
    });
};

jQuery.fn.initConditionalFieldsetToggle = function($watchElements, conditionCb, clearValues){
  clearValues = clearValues==undefined ? true : clearValues;
	var $this = this;
	var toggle = function(ev){
		if (conditionCb(ev)){
			$this.slideDown('slow', function(){ $this.css('overflow','visible')});
			$this.find('textarea').trigger('autosize:update');
		}else{
			$this.slideUp('slow');
      if (clearValues) {
        $this.find('input[type="text"], input[type="number"], textarea, select').val('');
        $this.find('input[type="radio"]').attr('checked', false);
        $this.find('input[type="checkbox"]').attr('checked', false);
      }
		}
	};
	$watchElements.on('change', toggle);
	toggle();
};

