/* eslint-disable */

function installEventListeners() {
	// for address preview
	$(
		'#ContactFirstName, #ContactLastName, #ContactOrganisation, #ContactDepartment, #ContactJobTitle, #ContactJobTitle2, #ContactStreet, #ContactZipCode, #ContactCity, #ContactCountry, .additional-address input')
		.each(function(i) {
			$(this).keyup($.throttle(250, updateAddressPreview));
		});
	$(
		'#ContactFormOfAddress, #ContactCountry, #primaryAddressRadio input[type="radio"], .additional-address select')
		.each(function(i) {
			$(this).change(updateAddressPreview);
		});
	$('#ContactTitle, #ContactTitleAnglo').each(function(i) {
		$(this).change($.throttle(250, updateAddressPreview));
	});

	$('#btnToggleSecondary').click(function() {
		$('.hideable').fadeToggle();
	});

	// toggle required of name fields depending on is_person
	var $ContactIsPerson = $('#ContactIsPerson');
	var toggleIsPersonNameReq = function(){
		var req = $ContactIsPerson.val()==1;
		$('#ContactLastName').required(req);
		$('#ContactFirstName').required(req);
	};
	$ContactIsPerson.on('change', toggleIsPersonNameReq);
	toggleIsPersonNameReq();
}

function createCkeToolbarWrapper() {
	var text = 'Hochzustellende Buchstaben markieren und dann hier klicken:';
	try {
		text = $.my.i18n.superscriptDescText;
	} catch (ignored) {
	}

	$('body').append(
		'<div id="ckeToolbarWrapper" class="shadow-box">'
			+ '<div id="ckeSharedSpace"></div>'
			+ '<span class="superscriptDesc">' + text + '</span>' + '</div>');
}

function installCKEditor() {

	var $editors = $('#ContactTitle, #ContactTitleAnglo, #ContactPersonalAddress, .superscriptEditor textarea');
	if ($editors.length == 0) {
		return;
	}

	if (typeof CKEDITOR == 'undefined') {
		// set base path as global var
		window.CKEDITOR_BASEPATH = $.my.getBaseUrl() + 'js/ckeditor/';

		var options = {
			dataType : "script",
			cache : true,
			url : $.my.getBaseUrl() + 'js/ckeditor/ckeditor.js?1'
		};

		$
			.ajax(options)
			.done(
				function() {

					$editors
						.each(function() {
							var textarea = $(this);
							var id = textarea.attr('id');
							var cke = CKEDITOR.replace(id, {
								customConfig : forUrl('/js/ckeditor_config.min.js')
							});

							// prepare label
							var btnSuperscript = $(
								'<span class="btn btn-mini"><i class="cke-icon-sup"></i></span>')
								.click(function() {
									cke.execCommand('superscript');
								});
							var superscriptDesc = $(
								'<span class="superscriptDesc">'
									+ $.my.i18n.superscriptDescText + '</span>')
								.prepend(btnSuperscript);
							var $label = $('label[for=' + id + ']');
							$label.append(superscriptDesc);

							var editor = '#cke_' + id;

							var showFunction = function(e) {
								var $editor = $(editor);
								var $container = $editor.closest('div');
								var top;
								top = ($container.offset().top
									- $container.outerHeight() - 49);
								$label.addClass('dropdown-menu').css(
									{
										padding : '4px 6px',
										position : 'absolute',
										top : top + 'px',
										left : ($container.offset().left)
											+ 'px',
										width : ($container.innerWidth() - 12)
											+ 'px'
									}).show();
							};

							cke.on('change', function(e) {
								this.updateElement();
								updateAddressPreview();
							});
							cke.on('focus', showFunction);
							$(document).on('mouseenter', editor, showFunction);

							cke.on('blur', function(e) {
								$label.hide();
							});

							var focusManager = new CKEDITOR.focusManager(cke);

							$(document).on('mouseleave', editor, function() {
								if (!focusManager.hasFocus) {
									$label.hide();
								}
							});

							$(this).parents('div.input')
								.addClass('superscript');
						});

				});
	}
}

function initCKEditorNew(opts) {

	var tooltipCss = function($container){
		var top = 0;
		var left = ($container.innerWidth());

		var css = {
			top : top + 'px',
			left : left + 'px'
		// ,width : ($container.innerWidth())
		// + 'px'
		};

		return css;
	};

	if(opts && opts.tooltipCss){
		tooltipCss = opts.tooltipCss;
	}

	var $editors = $('#ContactTitle, #ContactTitleAnglo, #ContactPersonalAddress, .superscriptEditor textarea');
	if ($editors.length == 0) {
		return;
	}

	if (typeof CKEDITOR == 'undefined') {
		// set base path as global var
		window.CKEDITOR_BASEPATH = $.my.getBaseUrl() + 'js/ckeditor/';

		var options = {
			dataType : "script",
			cache : true,
			url : $.my.getBaseUrl() + 'js/ckeditor/ckeditor.js?1'
		};

		$
			.ajax(options)
			.done(
				function() {

					$editors
						.each(function() {
							var textarea = $(this);
							var id = textarea.attr('id');
							var cke = CKEDITOR.replace(id, {
								customConfig : forUrl('/js/ckeditor_config.min.js')
							});

							// prepare hint
							var $btnSuperscript = $(
								'<span class="btn btn-primary btn-xs"><i class="fa fa-superscript"></i></span>')
								.click(function() {
									cke.execCommand('superscript');
								});
							var $superscriptDesc = $(
								'<div class="superscriptDesc dropdown-menu">'
									+ $.my.i18n.superscriptDescText + '</div>')
								.prepend($btnSuperscript);

							// we cant get jquery object here because editor is
							// not ready
							var editor = '#cke_' + id;

							var showFunction = function(e) {
								var $container = $(editor).closest('div.superscriptEditor');
//								$container.css('position', 'relative');
								$container.append($superscriptDesc.hide());

								$superscriptDesc.css(tooltipCss($container)).show();
							};

							cke.on('change', function(e) {
								this.updateElement();
                $(this.element.$).change();
								updateAddressPreview();
							});
							cke.on('focus', showFunction);
							$(document).on('mouseenter', editor, showFunction);

							cke.on('blur', function(e) {
								$superscriptDesc.hide();
							});

							var focusManager = new CKEDITOR.focusManager(cke);

							$(document).on('mouseleave', editor, function() {
								if (!focusManager.hasFocus) {
									$superscriptDesc.hide();
								}
							});

							$(this).parent().addClass('superscriptEditor');
						});

				});
	}
}

function installAjaxLinks() {
	$(document).on('click', 'a.ajax-open-modal', function() {
		var $modal = getAjaxModal();
		$modal.on('hide.bs.modal', function(){
			window.location.reload();
		});
		$modal.find('.modal-body').load($(this).attr('href'), function() {
			$modal.modal('show').modal('handleUpdate');
		});
		// finally to prevent actually making the link go anywhere
		return false;
	});

	$(document).on('click', 'a.attendDirect', function() {
		var id = $(this).attr('id').substring(15);
		var status = 'attends';
		$.post(forUrl('/invites/update_status/' + escape(id)), {
			id : id,
			status : status
		}, function(data, text) {
			window.location.reload();
		});
		return false;
	});

	$(document).on('click', 'a.btnLetterPreview', function(e) {
		return false;
	});

	$(document).on(
		'mouseenter',
		'a.btnLetterPreview',
		function(e) {
			var $link = $(this);
			$.getJSON(forUrl($link.attr('href')), function(data) {
				showAddressPreviewPopup(data, $link.offset());
			});
			return false;
		});

	$(document).on('mouseleave', 'a.btnLetterPreview', function() {
		$('#addressPreviewPopup').fadeOut(200);
		return false;
	});

	$(document).on(
		'click',
		'a.btnNotAttend',
		function(e) {
			var id = $(this).attr('id').substring(12);
			var status = 'attendsNot';

			if(window.confirm('Wollen Sie wirklich Ihre Teilnahme absagen?')){
				$.post(forUrl('/invites/update_status/' + escape(id)), {
					id : id,
					status : status
				}, function(data, text) {
					window.location.reload();
				});
			}
			e.preventDefault();
			return false;
		});

	$(document).on('click', '#overlayer a.close', function() {
		// Fade out the overlayer
		$('#overlayer').fadeOut(300);
		window.location.reload();
		return false;
	});

	$(document).on('change', 'select.rsvp-status-select', function() {
		var id = $(this).attr('id').substring(6);
		var status = $(this).find('option:selected').val();
		$.post(forUrl('/invites/update_status/' + escape(id)), {
			id : id,
			status : status
		}, function(data, text) {
			$('#statusCell' + id).empty().append(data);
		});
	});

	$('a.conversation-log').click(function(ev){
		// var win = window.open($(this).attr('href'),	'conversation-log','width=960,height=600,scrollbars=yes');
		// win.focus();
		openWindowCentered($(this).attr('href'), 'conversation-log', 960, 600);
		ev.preventDefault();
		return false;
	});

}

function bindEventDateAdjustments() {
	$('#EventStartDate').bind(
		'dateSelected',
		function(e, selectedDate, $td, state) {
			var selectedStartDate = new Date(selectedDate);
			// get current value of end date to compare to
			var currentEndDate = new Date($('#EventEndDateYear').val(), $(
				'#EventEndDateMonth').val() - 1, $('#EventEndDateDay').val());
			// eventually adjust end date
			if (currentEndDate < selectedStartDate) {
				updateDateSelects(selectedStartDate, 'EventEndDate');
			}
		});
	$('#EventStartTimeHour, #EventStartTimeMin').bind('change', function(e) {
		var selectedStartTime = new Date();
		selectedStartTime.setHours($('#EventStartTimeHour').val());
		selectedStartTime.setMinutes($('#EventStartTimeMin').val());

		var currentEndTime = new Date();
		currentEndTime.setHours($('#EventEndTimeHour').val());
		currentEndTime.setMinutes($('#EventEndTimeMin').val());
		if (currentEndTime < selectedStartTime) {
			updateTimeSelects(selectedStartTime, 'EventEndTime');
		}
	});
}

function installUsernameCheck(username, container, spinner, result) {
	var $username = $(username);
	var $container = $(container);
	var $spinner = $(spinner);
	var $result = $(result);

	$username.focusout(function() {
		$container.empty();

		$spinner.show().prependTo($container);

		var $username = $("#ContactUsername").val();
		$.get(forUrl('/ajax/contacts/check_username/') + $username, {

		}, function(data) {
			$spinner.hide();

			if (data == 1) {
				$result.addClass('text-success');
				$result.removeClass('text-danger');
				$result.text('Benutzername ist verfügbar');
			} else {
				$result.addClass('text-danger');
				$result.removeClass('text-success');
				$result.text('Benutzername ist bereits vergeben');
			}
			$result.show().prependTo($container);
		});
	});
}

function installCollapseFunction(selector) {
	var COLLAPSE_COOKIE_PREFIX = 'collapse-state-';
	$(selector).each(function() {
		$(this).addClass('pointer');
		$arrow = $(this).find('.arrow');
		$arrow.addClass('glyphicon');
		var panel = $(this).next();
		var id = panel.attr('id');
		var hide = true;
		if (id) {
			// we can check cookie
			var c = $.cookie(COLLAPSE_COOKIE_PREFIX + id);
			if (c && c == 'expanded') {
				hide = false;
			}
		}
		hide &= panel.find('.error-message').length == 0;
		if (hide) {
			panel.hide();
			$(this).find('.arrow').addClass('glyphicon-chevron-right');
		} else {
			panel.show();
			$(this).find('.arrow').addClass('down glyphicon-chevron-down');
		}
	}).click(function() {
		var panel = $(this).next();
		panel.toggle();
		$(this).find('.arrow').toggleClass('down');
		$(this).find('.arrow').toggleClass('glyphicon-chevron-down');
		$(this).find('.arrow').toggleClass('glyphicon-chevron-right');

		var id = panel.attr('id');
		if (id) {
			var val = panel.is(':visible') ? 'expanded' : 'collapsed';
			$.cookie(COLLAPSE_COOKIE_PREFIX + id, val, {
				path : '/'
			});
		}
		return false;
	});
}

/**
 * we don't want this called at all.
 */
var __deprecated_installEIP = function() {
	// XXX test error handling
	$("#error").ajaxError(function(e, jqxhr, settings, exception) {
		$(this).text("Error: " + exception);
	});

	var empty = '<span class="default-text">&lt;leer&gt;</span>';

	// get all obj elements
	$('.obj')
		.each(
			function() {
				var objId = $(this).attr('id');
				objId = objId.substring(objId.indexOf('_') + 1);
				// console.log('id: '+id);

				// find edit-in-place text fields of this obj
				// XXX migrate to jeditable
				var eipOptions = {
					field_type : 'text',
					url : forUrl('/ajax/' + $.my.url.controller + '/edit/'
						+ objId),
					saving_text : 'Speicher...', // XXX l10n!
					saving_animation_color : '#f7a922',
					hover_class : 'eip-hover',
					default_text : empty
				};
				$(this).find('.eip-text').editInPlace(eipOptions);
				eipOptions.field_type = 'textarea';
				eipOptions.show_buttons = true;
				$(this).find('.eip-textarea').editInPlace(eipOptions);

				// jeditable handles dates also
				$(this).find('.eip-date').editable(
					forUrl('/ajax/' + $.my.url.controller + '/edit/' + objId),
					{
						type : 'datepicker',
						id : 'element_id',
						name : 'update_value',
						placeholder : empty,
						datepicker : {
							dateFormat : 'yy-mm-dd'
						// minDate: '+1w' (this is working)
						}
					});

				$(this).find('.eip-select').each(
					function() {
						var id = $(this).attr('id');
						var model = id.substring(0, id.indexOf('_'));

						var loadUrl = forUrl('/ajax/' + model + 's/json_list');

						// var data = $.getJSON(forUrl('/ajax/'+ model +
						// 's/json_list'));
						// console.log(data);

						$(this).editable(
							forUrl('/ajax/' + controller + '/edit/' + objId),
							{
								type : 'select',
								loadurl : loadUrl,
								// data: $.evmgr.selectData[model],
								id : 'element_id',
								name : 'update_value',
								placeholder : empty,
								submit : 'OK',
								callback : function(value, settings) {
									$(this).html(
										$.evmgr.selectData[model][value]);
								}
							});
					});

				$(this).find('.edit-checkbox input[type=checkbox]').change(
					function() {
						var parent = $(this).parent();
						var element = parent.attr('id');

						var val = $(this).is(':checked');
						// console.log('checkbox for object '+ objId +' and
						// element '+ element
						// +' changed to '+val);

						var target = forUrl('/ajax/' + controller + '/edit/'
							+ objId);

						startSavingAnimation(parent, '#f7a922');
						$.post(target, {
							element_id : element,
							update_value : val
						}, function(data) {
							// success callback
							// console.log('success. data: '+data);
							stopSavingAnimation(parent);
						});
					});

				// find flag checkboxes of this contact
				$(this).find('.flag-edit').change(
					function() {
						var flagId = $(this).attr('id');
						flagId = flagId.substring(flagId.indexOf('_') + 1);
						var val = $(this).is(':checked');
						// console.log('checkbox for contact '+ objId +' and
						// flag '+flagId
						// +' changed to '+val);
						var target = forUrl('/ajax/' + $.my.url.controller
							+ '/edit/' + objId);
						var parent = $(this).parent();
						startSavingAnimation(parent, '#f7a922');
						$.post(target, {
							element_id : 'flag',
							update_value : val,
							flagId : flagId
						}, function(data) {
							// success callback
							// console.log('success. data: '+data);
							stopSavingAnimation(parent);
						});
					});
			});

	$(".eip-text").tooltip({
		tip : '#eip-tooltip',
		// effect: 'fade',
		position : 'top center',
		offset : [ -20, 0 ],
		// there is no delay when the mouse is moved away from the trigger
		delay : 0,
		opacity : 0.7
	});

}; // function installEIP

function initIndexMenu() {
	var iconOff = 'ui-icon-triangle-1-e';
	var iconOn = 'ui-icon-triangle-1-s';
	var buttons = $('#indexMenu > li > a');
	buttons.button({
		icons : {
			secondary : iconOff
		}
	});

	buttons.click(function() {
		// close others and reset all icons
		$(this).parent().siblings().find('> div').hide();
		buttons.button('option', 'icons', {
			secondary : iconOff
		});

		var reference = $('#indexActionPanel');

		var panel = $(this).next('div');
		if (panel.is(':visible')) {
			panel.hide();
		} else {
			var width = reference.outerWidth()
				- parseInt(panel.css('padding-left'))
				- parseInt(panel.css('padding-right'));
			$(this).button('option', 'icons', {
				secondary : iconOn
			});
			panel.css({
				width : width + "px"
			}).toggle();
		}
		return false;
	});
};

/**
 * does not work with concat plugin
 */
// export {
  // bindEventDateAdjustments,
  // createCkeToolbarWrapper,
  // initCKEditorNew,
  // initIndexMenu,
  // installAjaxLinks,
  // installCKEditor,
  // installCollapseFunction,
  // installEventListeners,
  // installUsernameCheck
// }
