$.fn.exportFrontend = function(options) {
	var defaultOptions = {
		formatSelect : '<select class="span3 form-control"><option value="xlsx">Excel 2007/2010/2013</option><option value="csv">CSV</option></select>',
		cancelButton : '<a class="btn btn-default">Cancel</a>',
		startButton : '<a class="btn btn-default">Start</a>',
		downloadButton : '<a class="btn btn-success span1">Download</a>'
	};

	options = $.extend({}, defaultOptions, options);

	var urlSelf = $.my.getControllerBaseUrl() + $.my.url.action;
	var requestStart;

	var ajaxSettings = {
		type : "POST",
		url : urlSelf + '.json?1',
		data : {
			format : 'xlsx',
			operation : 'proceed'
		},
		dataType : 'json'
	};

	var STATE_START = 'start';
	var STATE_DATA_QUERIED = 'data_queried';
	var STATE_DATA_SET_INTO_EXPORTER = "data_set_into_exporter";
	var STATE_OUTPUT_GENERATED = "output_generated";
	var STATE_CANCELLED = "cancelled";

	var enable = function() {
		this.removeClass('disabled');
		return this;
	};
	var disable = function() {
		this.addClass('disabled');
		return this;
	};

	var $container = this.first();

	var btnFormatSelect = $(options.formatSelect);
	btnFormatSelect.change(function() {
		ajaxSettings.data.format = $(this).val();
	});

	var btnCancel = $(options.cancelButton);
	btnCancel.enable = $.proxy(enable, btnCancel);
	btnCancel.disable = $.proxy(disable, btnCancel);
	btnCancel.disable().click(function() {
		if (!$(this).hasClass('disabled')) {
			queryServer({
				operation : 'cancel'
			});
		}
	});
	var btnStart = $(options.startButton);
	btnStart.enable = $.proxy(enable, btnStart);
	btnStart.disable = $.proxy(disable, btnStart);
	btnStart
	// .disable()
	.click(function() {
		if (!$(this).hasClass('disabled')) {
			$container.find('.alert').remove();
			btnDownload.disable();
			btnStart.disable();
			btnCancel.enable();
			queryServer({
				operation : 'proceed',
				reset_export : true
			});
		}
	});
	var btnDownload = $(options.downloadButton);
	btnDownload.enable = $.proxy(function() {
		// call standard enable
		$.proxy(enable, this)();

		var $tbBottom = $('.toolbar-bottom');
		if ($tbBottom.length == 0) {
			$tbBottom = $('<div class="toolbar-bottom top15"></div>');
			$tbBottom.append(this);
		}
		$container.append($tbBottom.show());
		return this;
	}, btnDownload);
	btnDownload.disable = $.proxy(function() {
		// call standard disable
		$.proxy(disable, this)();

		$('.toolbar-bottom').hide();
		return this;
	}, btnDownload);
	btnDownload.disable().click(function() {
		if (!$(this).hasClass('disabled')) {
			startDownload();
		}
	});

	var progressBar = $('<div class="progress"><div class="progress-bar progress-bar-striped active" style="width: 0%;"></div></div>');
	var notify = function(msg, clazz, error) {
		if (!clazz) {
			clazz = '';
		}
		var alert = $('<div class="alert ' + clazz + '"></div>');
		if (error == true) {
			alert.addClass('alert-danger');
		} else if (error == null) {
			alert.addClass('alert-info');
		} else {
			alert.addClass('alert-success');
		}
		alert.append(msg);
		$container.append(alert);
	};
	var done = function(data, textStatus, obj) {
		var requestEnd = new Date().getTime();
		var duration = (requestEnd - requestStart) / 1000;
		duration = Math.round(duration * 100) / 100;
		data[$.my.i18n.duration] = duration + 's';
		var status = data.status;
		delete data.status;
		var message = data.message;
		delete data.message;
		if (data.progress && data.progress < 100) {
			progressBar.show().find('.progress-bar').css('width',
				data.progress + '%');
		} else {
			progressBar.find('.progress-bar').css('width', data.progress + '%');
			progressBar.hide('slow');
		}
		delete data.progress;
		var msg = message + '<span class="pull-right">' + $.inspect(data)
			+ '</span>';

		// always remove alerts with class of status start
		$container.find('.alert.start').remove();

		switch (status) {
		case STATE_OUTPUT_GENERATED:
			var error = false;
			notify(msg, status, error);
			// progressBar.hide('slow');
			btnStart.enable();

			btnDownload.enable()
			break;
		case STATE_CANCELLED:
			btnCancel.disable();
			btnStart.enable();
			btnDownload.disable();
			// progressBar.hide('slow');
			notify(msg, status);
			break;
		default:
			btnDownload.disable();
			notify(msg, status);
			queryServer();
			break;
		}
	};
	var fail = function(obj, textStatus, msg) {
		// console.log('error: ', obj, textStatus, msg);
		if (textStatus == 'parsererror') {
			msg = obj.responseText;
		}
		progressBar.hide();
		btnStart.enable();
		notify(msg, 'fail', true);
	};
	var queryServer = function(data) {
		var opts = $.extend(true, {}, ajaxSettings);
		$.extend(opts.data, data);
		requestStart = new Date().getTime();
		$.ajax(opts).done(done).fail(fail);
	};
	var startDownload = function() {
		var iframe = $('<iframe src="" style="display:none"></iframe>');
		$container.append(iframe);
		iframe.attr('src', urlSelf + '/download');
	};

	var toolbar = $('<div class="controls-row row bottom15"></div>').append(
		$('<div class="col-sm-3"></div>').append(btnFormatSelect)).append(
		$('<div class="btn-group span3 col-sm-3"></div>').append(btnStart)
			.append(btnCancel));

	$container.append(toolbar);
	$container.append(progressBar.hide());

};
