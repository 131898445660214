/**
 * Project: FormEditor Description: Author: Nils License: GPLv3
 *
 */

// the semi-colon before function invocation is a safety net against
// concatenated
// scripts and/or other plugins which may not be closed properly.
;
(function($, window, document, undefined) {

	// undefined is used here as the undefined global variable in ECMAScript 3
	// is
	// mutable (ie. it can be changed by someone else). undefined isn't really
	// being
	// passed in so we can ensure the value of it is truly undefined. In ES5,
	// undefined
	// can no longer be modified.

	// window and document are passed through as local variable rather than
	// global
	// as this (slightly) quickens the resolution process and can be more
	// efficiently
	// minified (especially when both are regularly referenced in your plugin).

	// Create the defaults once
	var pluginName = "formEditor", defaults = {
		editableSelector : 'fieldset.fieldset-editable',
		rowSelector : '.controls-row',
		rowFieldsetSelector : '.form-horizontal',
		draggableSelector : 'div',
		saveButton : '<div class="btn fieldset-save">Save</div>',
		editButton : '<div class="btn fieldset-edit">Edit</div>',
		iconHandle : 'icon-reorder',
		iconGrow : 'icon-step-forward',
		iconShrink : 'icon-step-backward',
		hanlde : '.handle'
	};

	// The actual plugin constructor
	function Plugin(element, options) {
		this.element = element;

		// jQuery has an extend method which merges the contents of two or
		// more objects, storing the result in the first object. The first
		// object
		// is generally empty as we don't want to alter the default options for
		// future instances of the plugin
		this.options = $.extend({}, defaults, options);

		this._defaults = defaults;
		this._name = pluginName;

		this.init();
	}

	Plugin.prototype = {
		fieldsets : null,

		init : function() {
			// You already have access to the DOM element and
			// the options via the instance, e.g. this.element
			// and this.options

			// init all fieldsets
			this.fieldsets = $(this.element)
				.find(this.options.editableSelector).addBack(
					this.options.editableSelector);

			this.saveButton = $(this.options.saveButton);
			this.saveButton.click($.proxy(this.save, this));

			this.editButton = $(this.options.editButton);
			this.editButton.click($.proxy(this.edit, this));

			// install edit to each fieldset
			this.fieldsets.prepend(this.editButton);
		},

		parseFields : function(row) {
			var self = this;
			var fields = {};
			var fieldCount = 0;
			$(row).find('> ' + self.options.draggableSelector).each(function() {
				fields['field' + fieldCount++] = self.getFieldInfo(this);
			});
			return fields;
		},

		parseRows : function(fieldset) {
			var self = this;
			var rows = {};
			var rowCount = 0;
			$(fieldset).find(self.options.rowSelector).each(function() {
				var row = {
					fields : self.parseFields(this)
				};
				rows['row' + rowCount++] = row;
			});
			return rows;
		},

		parseFieldsets : function() {
			var self = this;
			var fieldsets = {};
			this.fieldsets.each(function() {
				var fieldset = {
					// TODO legend
					rows : self.parseRows(this)
				};
				fieldsets[$(this).attr('id')] = fieldset;
			});
			return fieldsets;
		},

		save : function() {

			// deactivate sortable BEFORE scanning
			this.destroySortable();

			// XXX try to get these from actual form
			var model = prompt('Save as model:');
			var action = prompt('Save as action:');

			var form = {};
			form[model] = {};
			form[model][action] = {
				fieldsets : this.parseFieldsets()
			};
			// console.log(form);

			$.ajax({
				type : 'POST',
				url : forUrl('/ajax/configurations/save_form'),
				data : form,
				dataType : 'json'
			}).done($.my.successHandler).fail($.my.errorHandler);
		},

		// start editing the fieldsets...
		edit : function() {

			// load jquery-ui draggable, droppable and sortable on demand
			var options = {
				dataType : "script",
				cache : true,
				url : $.my.getBaseUrl()
					+ 'js/jquery-ui-1.10.1.only-drag-drop-sort.min.js'
			};

			$.ajax(options).done(
				$.proxy(function() {
					// remove edit button, add save button
					this.editButton.remove();
					$(this.element).prepend(this.saveButton);

					this.rows = this.fieldsets.find(this.options.rowSelector)
						.addBack(this.options.rowFieldsetSelector);
					this.initSortable();

					this.initResize();
				}, this));
		},

		initSortable : function() {
			this.draggables = this.rows.find('> '
				+ this.options.draggableSelector);
			this.draggables.addClass('draggable').append(
				'<div class="bottom">\n<div class="shrink"><i class="'
					+ this.options.iconShrink
					+ '"></i></div>\n<div class="grow"><i class="'
					+ this.options.iconGrow
					+ '"></i></div>\n<div class="handle"><i class="'
					+ this.options.iconHandle + '"></i></div>\n</div>');

			this.rows.addClass('ui-sortable');
			this.rows.sortable({
				handle : this.options.handle,
				connectWith : this.options.rowSelector,
				start : function(e, ui) {
					ui.placeholder.addClass('span3'); // it just needs any
					// span class
					ui.placeholder.width(ui.item.width());
					ui.placeholder.height(ui.item.height());
				},
				placeholder : 'draggable-placeholder'
			});
			this.rows.disableSelection();

		},

		destroySortable : function() {
			this.draggables.find('.bottom').remove();
			this.draggables.removeClass('draggable');
			this.rows.sortable('destroy');
		},

		initResize : function() {
			// resize (change span class)
			$(this.draggables).on('click', '.grow, .shrink',
				$.proxy(this.resize, this));

		},

		resize : function(e) {
			// console.log('change span class...');
			var $div = $(e.delegateTarget);
			var currentSpan = this.currentSpan($div);
			if (!currentSpan) {
				currentSpan = 3;
			}
			var newSpan = $(e.currentTarget).hasClass('grow') ? currentSpan + 1
				: currentSpan - 1;
			if (newSpan > 12) {
				newSpan = 12;
			} else if (newSpan < 1) {
				newSpan = 1;
			}
			$div.find('.span' + currentSpan).addBack().removeClass(
				'span' + currentSpan).addClass('span' + newSpan);
		},

		currentSpan : function(el) {
			var m = /\bspan(\d+)/.exec(el[0].className);
			if (m[1]) {
				return parseInt(m[1]);
			} else {
				return null;
			}
		},

		setAttrIfExists : function($el, attr, obj) {
			var a = $el.attr(attr);
			if (a) {
				obj[attr] = a;
				return true;
			} else {
				return false;
			}
		},

		setDataIfExists : function($el, attr, obj) {
			var a = $el.data(attr);
			if (a) {
				obj[attr] = a;
				return true;
			} else {
				return false;
			}
		},

		getFieldInfo : function(div) {
			var field = {
				span : this.currentSpan($(div))
			};

			$.extend(field, $(div).data());

			// fieldname or spacer
			if (!field.fieldname) {
				field.spacer = true;
				this.setAttrIfExists($(div), 'id', field);
			}

			// textarea? -> rows
			var $ta = $(div).find('textarea');
			if ($ta.length > 0) {
				this.setAttrIfExists($ta, 'rows', field);
			}

			return field;
		}

	};

	// A really lightweight plugin wrapper around the constructor,
	// preventing against multiple instantiations
	$.fn[pluginName] = function(options) {
		return this
			.each(function() {
				if (!$.data(this, "plugin_" + pluginName)) {
					$.data(this, "plugin_" + pluginName, new Plugin(this,
						options));
				}
			});
	};

})(jQuery, window, document);

